var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumb',{attrs:{"home":false,"breadcrumbs":[
      { label: '制程管理', to: '/workflow' },
      {
        label: '详情',
        name: "workflow-detail",
        query: {
          workflowId: this.$route.query.workflowId,
          groupId: this.$route.query.groupId,
        },
      },
      {
        label: '工序编辑',
      } ]}}),_c('el-card',{staticClass:"box-card",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("工序编辑")])]),_c('FormRender',{attrs:{"schema":_vm.schema,"data":_vm.procedureData,"config":_vm.procedureFormConf},scopedSlots:_vm._u([{key:"submit_btn",fn:function(){return [_c('el-button',{staticStyle:{"float":"right","margin-bottom":"20px"},attrs:{"type":"primary","disabled":_vm.procedureData.submit_btn},on:{"click":_vm.onEditProcedureSubmit}},[_vm._v("保存")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }