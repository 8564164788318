<template>
  <div>
    <Breadcrumb
      :home="false"
      :breadcrumbs="[
        { label: '制程管理', to: '/workflow' },
        {
          label: '详情',
          name: `workflow-detail`,
          query: {
            workflowId: this.$route.query.workflowId,
            groupId: this.$route.query.groupId,
          },
        },
        {
          label: '工序编辑',
        },
      ]"
    />
    <el-card class="box-card" style="margin-top: 20px">
      <div slot="header" class="clearfix">
        <span>工序编辑</span>
      </div>
      <FormRender
        :schema="schema"
        :data="procedureData"
        :config="procedureFormConf"
      >
        <template #submit_btn>
          <el-button
            type="primary"
            @click="onEditProcedureSubmit"
            style="float: right; margin-bottom: 20px"
            :disabled="procedureData.submit_btn"
            >保存</el-button
          >
        </template>
      </FormRender>
    </el-card>
  </div>
</template>

<script>
import schema from "./procedure.schema.json";
import { uris, attachId } from "./../../api/uri";
import FormRender from "enn-schema-form-render";
import Breadcrumb from "enn-breadcrumb";

import _ from "lodash";
import axios from "axios";

export default {
  components: {
    FormRender,
    Breadcrumb,
  },
  props: {
    processId: String,
    workflowId: String,
    procedureId: String,
  },
  created() {
    this.getProcedure();
  },
  data() {
    return {
      procedureData: {},
      schema,
      procedureFormConf: {
        getServerOptions: this.getServerOptions.bind(this),
      },
    };
  },
  methods: {
    onEditProcedureSubmit() {
      this.updateProduce();
    },
    async getServerOptions() {
      const {
        data: {
          data: { list: prcedureList },
        },
      } = await axios.get(
        `${uris.procedure}?pageSize=100&processId=${this.$route.query.processId}`
      );
      let options = this.optionsFormat(prcedureList);
      return options;
    },
    optionsFormat(list, valueKey = "id", labelKey = "name") {
      if (!Array.isArray(list)) {
        return [];
      }
      let str = JSON.stringify(list)
        .replace(new RegExp(`"${valueKey}":`, "g"), '"value":')
        .replace(new RegExp(`"${labelKey}":`, "g"), '"label":');
      return JSON.parse(str);
    },
    async getProcedure() {
      // 获取工序单例
      const {
        data: { data: procedure },
      } = await axios.get(
        `${uris.procedureJson}/${this.$route.query.procedureId}`
      );
      procedure.isParent = procedure.parentId ? true : false;
      procedure.submit_btn = true;
      this.procedureData = procedure;
    },
    async updateProduce() {
      const res = await axios.put(
        `${uris.procedure}/${this.$route.query.procedureId}`,
        {
          name: this.procedureData.name,
          isParent: this.procedureData.isParent,
          parentId: this.procedureData.parentId,
          procedureJson:this.procedureData.procedureJson,
        }
      );
      await this.getProcedure();
      this.$message({
        type: res.data.code !== 0 ? "error" : "success",
        message: res.data.code !== 0 ? res.data.message : "修改成功",
      });
    },
  },
};
</script>

<style scoped>
</style>